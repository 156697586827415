// routes
// import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../../routes/paths';
// config
// import { PATH_AFTER_LOGIN } from '../../../config';
// components
import Iconify from '../../../components/iconify';
import { SignOut } from '../../../components/Sf/AzureAD/SignOut';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/home',
  },
  {
    title: 'Assessment',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/assessment',
  },
  {
    title: 'Sign-out',
    icon: <Iconify icon="eva:book-open-fill" />,
    element: <SignOut />,
    path: '#',
  },
];

export default navConfig;
