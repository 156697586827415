// import { apiConfig } from "./sfAuthConfig";

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AD_CLIENT_ID}`,
    authority: 'https://login.microsoftonline.com/Common', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: `${process.env.REACT_APP_BASE_URI}/auth.html`,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    `api://${process.env.REACT_APP_AD_CLIENT_ID_API}/User.Read`,
    `api://${process.env.REACT_APP_AD_CLIENT_ID_API}/Organisation.Read`,
    `api://${process.env.REACT_APP_AD_CLIENT_ID_API}/Assessment.ReadWrite`,
  ],
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  apiHello: {
    endpoint: `${process.env.REACT_APP_SF_API_URI}`,
    scopes: [
      `api://${process.env.REACT_APP_AD_CLIENT_ID_API}/User.Read`,
      `api://${process.env.REACT_APP_AD_CLIENT_ID_API}/Organisation.Read`,
      `api://${process.env.REACT_APP_AD_CLIENT_ID_API}/Assessment.ReadWrite`,
    ],
  },
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  endpoint: 'https://graph.microsoft.com/v1.0',
  scopes: ['User.Read'],
};
