import { Navigate, useRoutes } from 'react-router-dom';
// auth
// import AuthGuard from '../auth/AuthGuard';
// import GuestGuard from '../auth/GuestGuard';
// layouts
// import CompactLayout from '../layouts/compact';

import Main from '../layouts/main';
// config
// import { PATH_AFTER_LOGIN } from '../config';
//
import { Assessment, Home, Page404 } from './elements';
// import { Page500, Page403, Page404, ComingSoonPage, Assessment, Home } from './elements';

// SF
// import Apidoc from '../layouts/apidoc/Apidoc';
// import OrganisationsLayout from '../layouts/configuration/organisations';
// import AssessmentConfig from '../layouts/configuration/assessments/';
// import Research from '../layouts/research';
// import AssessmentSelect from '../layouts/iTAssessment';
// import Home from '../layouts/home';
// import AssessmentReport from '../layouts/reports/AssessmentReport';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/home" replace />,
    },
    {
      path: '/home',
      element: <Main />,
      children: [{ element: <Home />, index: true }],
    },
    {
      path: '/assessment',
      element: <Main />,
      children: [{ element: <Assessment />, index: true }],
    },
    { path: '404', element: <Page404 /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
