import { useLocation } from 'react-router-dom';
// @mui
import { Box, Container, Typography } from '@mui/material';
// routes
// import { PATH_PAGE } from '../../routes/paths';
// _mock
// components
import Logo from '../../components/logo';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Footer() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography mx={1}>Security Frameworks</Typography>

        <Typography variant="caption" component="div">
          © All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return isHome ? simpleFooter : simpleFooter;
}
