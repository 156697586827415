// routes
// import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../../routes/paths';
// config
// import { PATH_AFTER_LOGIN } from '../../../config';
// components
import Iconify from '../../../components/iconify';
import { SignIn } from '../../../components/Sf/AzureAD/SignIn';
// ----------------------------------------------------------------------

const navConfigUnAuthed = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/home',
  },

  {
    title: 'Sign-in',
    icon: <Iconify icon="eva:book-open-fill" />,
    element: <SignIn />,
    path: '#',
  },
];

export default navConfigUnAuthed;
