import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
// import Button from 'react-bootstrap/Button';

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOut = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    console.log('asugjhvb');
    if (logoutType === 'popup') {
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/', // redirects the top level app after logout
      });
    }
  };

  useEffect(() => {
    handleLogout('popup');
  }, []);

  return <div></div>;
};
